import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/themes/gatsby-theme-zebra/src/components/mdx-layout-pages.js";
import Obfuscate from "react-obfuscate";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7a65379589f6002a79fe66c7fbc504f3/9b902/anna-at-a-workshop.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMCAQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGW/NcskI7DgP/EABoQAQEAAgMAAAAAAAAAAAAAAAECAAMSIUL/2gAIAQEAAQUCuSI1zN6wAqikc5Bnq+oLU//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAAICAwEAAAAAAAAAAAAAAAABIUEQETJh/9oACAEBAAY/AmxPFwbUHQxP0o//xAAbEAADAAMBAQAAAAAAAAAAAAAAAREhMUFhgf/aAAgBAQABPyFVJeVks56SCEl1qsI2FD2EImj6NIz6MwW00f/aAAwDAQACAAMAAAAQgA88/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAITFB0f/aAAgBAQABPxA0kSUAuRsMhF46a4D8zLijYl3OAAp2TzwMwFKXh+5gQSUc7ZmeKQJ0NzhrF4/Hf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Anna at a workshop",
            "title": "Anna at a workshop",
            "src": "/static/7a65379589f6002a79fe66c7fbc504f3/82472/anna-at-a-workshop.jpg",
            "srcSet": ["/static/7a65379589f6002a79fe66c7fbc504f3/5aa83/anna-at-a-workshop.jpg 150w", "/static/7a65379589f6002a79fe66c7fbc504f3/afcd2/anna-at-a-workshop.jpg 300w", "/static/7a65379589f6002a79fe66c7fbc504f3/82472/anna-at-a-workshop.jpg 600w", "/static/7a65379589f6002a79fe66c7fbc504f3/2d017/anna-at-a-workshop.jpg 900w", "/static/7a65379589f6002a79fe66c7fbc504f3/c35de/anna-at-a-workshop.jpg 1200w", "/static/7a65379589f6002a79fe66c7fbc504f3/9b902/anna-at-a-workshop.jpg 2976w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am a textile artist working with fabric, paper and mixed media. Taking blank cloth as my starting point, I build a palette of colour and texture by dyeing, painting and printing. This cloth becomes my art quilts, through a process of collage, piecing, and stitching. `}</p>
    <p>{`My inspiration comes from the world around me, I observe, take photographs and use these photos to inform design. Landscapes, seascapes, nature and decay form the basis of my work, which is abstract and contemporary.`}</p>
    <p>{`I completed my City and Guilds Diploma in Stitched Textiles (Patchwork and Quilting) in 2018 and that year was shortlisted for the student bursary award, exhibiting in the student showcase at the Festival of Quilts. In 2019 I was awarded the Anne Tuck Prize for my entry into Contemporary Quilt’s annual challenge. Re-Vision.`}</p>
    <h3 {...{
      "id": "exhibitions"
    }}>{`Exhibitions`}</h3>
    <ul>
      <li parentName="ul">{`2020 Fragmentation, Contemporary Quilt Challenge`}</li>
      <li parentName="ul">{`2019 Re-Vision, Contemporary Quilt Challenge`}</li>
      <li parentName="ul">{`2019 Summer Show, Westbury Fabric and Fibre Guild, Milton Keynes`}</li>
      <li parentName="ul">{`2018 MK Lit Fest, Exhibition with Art to Stitch, Milton Keynes`}</li>
      <li parentName="ul">{`2018 Remembering, Open Door Gallery, Berkhamsted`}</li>
      <li parentName="ul">{`2018 Graduate Showcase, Festival of Quilts, Birmingham`}</li>
      <li parentName="ul">{`2018 Creative Stitch Graduate Show, Wickham Market`}</li>
      <li parentName="ul">{`2016 Creative Stitch Graduate Show, Wickham Market`}</li>
    </ul>
    <h3 {...{
      "id": "awards"
    }}>{`Awards`}</h3>
    <p>{`2019, Anne Tuck Bursary (from Contemporary Quilt)`}</p>
    <p>{`2018, shortlisted for student bursary award, Festival of Quilts, Birmingham.`}</p>
    <h3 {...{
      "id": "education"
    }}>{`Education`}</h3>
    <p>{`City and Guilds Level 3 Diploma in Design and Craft - Stitched Textiles (Patchwork and Quilting), 2018, Creative Stitch Suffolk.`}</p>
    <p>{`City and Guilds Level 3 Certificate in Design and Craft - Stitched Textiles (Patchwork and Quilting), 2016, Creative Stitch Suffolk.`}</p>
    <Obfuscate email="me@annawoodhead.com" headers={{
      subject: "A question about www.annawoodhead.com"
    }} mdxType="Obfuscate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      