/** @jsx jsx */

import { jsx, Styled } from 'theme-ui'
import React from 'react'
import ZebraLayout from './zebra-layout'
//import SEO from "./SEO"

function MdxLayout3({ children, pageContext }) {
  const { title } = pageContext.frontmatter
  
  return (
    <ZebraLayout>
      {/* <SEO title={title} /> */}
      <article
        sx = {{
          maxWidth: '800px'          
        }}>
        <header>
          <Styled.h1>{title}</Styled.h1>
        </header>
        {children}
      </article>
    </ZebraLayout>
  )
}

export default MdxLayout3